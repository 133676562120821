<template>
    <div class="about">
        <banner :image='imageName' :title='"关于我们"'/>

        <separate :title='"什么是六六找房？"'/>
        <br>
        <p class='paragraph'>
            六六找房，原英文名称“ZUBER”，于 2015 年在上海创立，致力于让租房这件事情变得更简单。 在六六找房，我们严格审核房源真实信息，房源视频覆盖率超过 95%，和房东直接沟通，提高租住双方沟通效率， 一键快捷在线签约。 截止 2019 年注册用户超数百万，覆盖了北京、上海、广州、深圳、杭州等多个热门城市，公司总部位于上海。
        </p>
        
        <div class='intro row'>
            <div class='col-lg-4 intro-item' v-for='intro in intros' :key='intro.icon'>
                <img :src='`${require("@/assets/us_icon_" + intro.icon + "@2x.png")}`' width='80'/>
                <div class='intro-item__title'>{{intro.name}}</div>
                <div class='intro-item__context'>{{intro.context}}</div>
            </div>
        </div>

        <separate :title='"六六找房发展史"'/>
        <history/>
        <official-footer/>
    </div>
</template>

<script>
    import Banner from '@/components/Banner'
    import Separate from '@/components/Separate'
    import History from '@/components/History'
    import OfficialFooter from '@/components/Footer'

    export default {
        components: {
            Banner,
            Separate,
            History,
            OfficialFooter
        },
        data () {
            return {
                imageName: 'bg_us',
                intros: [
                    { name: '愿景', context: '房屋直租领域第一交易平台', icon: 'dream' },
                    { name: '使命', context: '让租房更简单更省心', icon: 'mission' },
                    { name: '价值观', context: '真实、高效', icon: 'value' }
                ]
            }
        }
    }
</script>

<style lang='less'>
    .intro {
        max-width: 860px;
        margin: 0 auto;
        .intro-item {
            text-align: center;
            margin-bottom: 60px;
            .intro-item__title {
                font-size: 14px;
                margin-top: 10px;
                margin-bottom: 10px;
                font-weight: 600;
            }
            .intro-item__context {
                font-size: 12px;
            }
        }
    }
</style>

