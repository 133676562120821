<template>
    <div id="app">
        <navbar/>
        <transition name='fade' mode='out-in'>
            <router-view/>
        </transition>
    </div>
</template>

<script>
    import Navbar from '@/components/Navbar'

    export default {
        components: {
            Navbar
        }
    }
</script>

<style lang="less">
    @media (max-width: 992px) {
        .screen {
            display: none !important;
        }
        .mobile {
            display: block;
        }
        .screen-inline {
            display: none !important;
        }
        .mobile-inline {
            display: inline-block;
        }
    }
    
    @media (min-width: 992px) {
        .mobile {
            display: none !important;
        }
        .mobile-inline {
            display: none !important;
        }
        .screen {
            display: block;
        }
        .screen-inline {
            display: inline-block;
        }
    }
    #app {
        color: #333;
    }
    .fade-enter-active, .fade-leave-active {
      transition: opacity .3s;
    }
    .fade-enter, .fade-leave-to {
      opacity: 0;
    }
    .push-enter-active, .push-leave-active {
        transition: all ease .2s;
        opacity: 1;
    }
    .push-enter, .push-leave-to {
        opacity: 0;
        transform: translateY(-10%);
    }
    .separate-title {
        font-size: 26px;
        font-weight: 400;
        text-align: center;
        margin: 20px auto;
    }
    .separate-intro {
        font-size: 11pt;
        color: #999;
        text-align: center;
    }
    .separate-arrow {
        display: block;
        margin: 0 auto;
    }
    .paragraph {
        max-width: 860px;
        margin: 40px auto;
        font-size: 14px;
        text-align: center;
        line-height: 2em;
        padding: 0 15px;
    }
    .official-footer {
        text-align: center;
        padding: 60px 0;
        text-align: center;
        font-size: 12px;
        background: #f7f7f7;
        .official-footer__context {
            margin-top: 20px;
        }
    }
</style>


