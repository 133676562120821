<template>
    <div class='history'>
        <div class='history-phase' v-for='history in histories' :key='history.year'>
            <div class='history-phase__year' :style="{backgroundColor: history.color}">{{history.year}}</div>
            <div class='history-phase__issue' v-for='issue in history.issues' :key='issue'>
                <div class='history-phase__issue_context'>{{issue}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                histories: [
                    {
                        year: 2015,
                        color: '#66D4C3',
                        issues: [
                            '5月 一起租产品上线',
                            '10月 IOS版上线'
                        ]
                    },
                    {
                        year: 2016,
                        color: '#FFC673',
                        issues: [
                            '1月 Anroid版上线',
                            '2月 一起租更名Zuber',
                            '3月 Zuber获得天使融资',
                            '4月 Zuber视频看房功能上线',
                            '5月 Zuber押金托管功能上线'
                        ]
                    },
                    {
                        year: 2017,
                        color: '#60AFE0',
                        issues: [
                            '4月 Zuber开始人工审核房源信息',
                            '5月 C2C电子租房合同功能上线',
                            '6月 Zuber获得Pre-A轮融资',
                            '7月 Zuber强制实名登记',
                            '8月 Zuber荣获英诺天使年度新秀企业',
                            '9月 Zuber平台上线B端房源',
                            '10月 Zuber商家房源管理功能上线',
                            '11月 Zuber房源视频覆盖率高达95%'
                        ]
                    },
                    {
                        year: 2018,
                        color: '#66D4C3',
                        issues: [
                            '4月 Zuber完成A轮融资',
                            '5月 Zuber微信小程序上线',
                            '11月 上海地区实现网签合同备案'
                        ]
                    },
                    {
                        year: 2019,
                        color: '#FFC673',
                        issues: [
                            '5月 Zuber更名六六找房'
                        ]
                    }
                ]
            }
        }   
    }
</script>

<style lang="less">
    .history {
        max-width: 400px;
        margin: 60px auto;
        text-align: center;
        position: relative;
        &:before {
            content: '';
            width: 2px;
            height: 100%;
            background: #edeff3;
            position: absolute;
            top: 0;
            left: calc(50% - 1px);
            z-index: -1;
        }
        .history-phase {
            .history-phase__year {
                width: 80px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                color: #fff;
                font-size: 16px;
                border-radius: 4px;
                margin: 30px auto;
            }
            .history-phase__issue {
                font-size: 12px;
                width: 12px;
                height: 12px;
                background: #66D4C3;
                border: 3px solid #edeff3;
                border-radius: 100%;
                position: relative;
                margin: 0 auto;
                margin-bottom: 30px;
                &:last-child {
                    margin-bottom: 0px;
                }
                .history-phase__issue_context {
                    position: absolute;
                    right: 20px;
                    top: -6px;
                    width: 210px;
                    text-align: right;
                }
                &:nth-child(even) .history-phase__issue_context{
                    right: auto;
                    left: 20px;
                    text-align: left;
                }
            }
        }
    }
    @media (max-width: 992px) {
        .history-phase__issue_context {
            width: 160px !important;
        }
    }
</style>

