<template>
    <div>
        <h4 class='separate-title'>{{title}}</h4>
        <p v-if='intro' class='separate-intro'>{{intro}}</p>
        <!-- <img src='@/assets/us_icon_adorn@2x.png' width="12" class='separate-arrow'/> -->
    </div>
</template>

<script>
    export default {
        props: ['title', 'intro']
    }
</script>
