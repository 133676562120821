<template>
    <div class='navbar'>
        <div class='navbar-mask screen'></div>
        <div class='navbar-content'>
            <div class='container' style='overflow: hidden'>
                <router-link class='navbar-content__brand' :to='{name: "home"}'>
                    <img src='@/assets/logo_white.png' height="25" class='screen-inline'/>
                    <img src='@/assets/logo@2x.png' height="25" class='mobile-inline'/>
                </router-link>
                <div class='navbar-content__menu screen'>
                    <div
                    v-for='menu in menus'
                    :key='menu.route'
                    :to='{name: menu.route}'
                    @click='nav(menu)'
                    :class='["menu-link", menu.route == $route.name ? "active" : ""]'>
                        {{menu.name}}
                    </div>
                </div>
                <div class='navbar-content__menu mobile-inline' @click='drop'>
                    <img src='@/assets/nav_icon_menu@2x.png' height="18" class='mobile-inline'/>
                </div>
            </div>

            <transition name='push' mode='out-in'>
                <div class='navbar-dropdown mobile' v-if='toggle'>
                    <div
                    v-for='menu in menus'
                    :key='menu.route'
                    @click='nav(menu)'
                    :class='["navbar-dropdown__item", menu.route == $route.name ? "active" : ""]'>
                        {{menu.name}}
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                toggle: false,
                menus: [
                    { name: '首页', route: 'home' },
                    { name: '个人业主', route: 'trust' },
                    { name: '商家入口', link: 'https://agent.66zhizu.com' },
                    { name: '关于我们', route: 'contact' }
                    // { name: '联系我们', route: 'contact' }
                ]
            }
        },
        methods: {
            drop () {
                this.toggle = !this.toggle
            },
            nav (menu) {
                if (menu.route) this.$router.push({name: menu.route})
                else window.open(menu.link)

                this.toggle = false
            }
        }
    }
</script>


<style lang="less">
    .navbar {
        height: 60px;
        line-height: 60px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 99;
        .navbar-mask {
            background: #000;
            opacity: .1;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
        .navbar-content {
            color: #fff;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            font-size: 14px;
            .navbar-content__brand {
                float: left;
            }
            .navbar-content__menu {
                float: right;
                .menu-link {
                    cursor: pointer;
                    display: inline-block;
                    color: #fff;
                    text-decoration: none;
                    margin-left: 50px;
                    font-weight: 200;
                    &.active {
                        font-weight: 400;
                    }
                }
            }
        }
    }
    .navbar-dropdown {
        position: fixed;
        top: 60px;
        left: 0;
        right: 0;
        .navbar-dropdown__item {
            background: #fff;
            color: #333;
            font-size: 18px;
            display: block;
            height: 60px;
            line-height: 60px;
            padding: 0 15px;
            border-top: 1px solid #ebebeb;
            font-weight: 300;
            text-decoration: none;
            &.active {
                font-weight: 400;
            }
        }
    }
    @media (max-width: 992px) {
        .navbar {
            position: fixed;
            .navbar-content {
                background: #fff;
            }
        }
    }
</style>

