<template>
    <div class="about">
        <banner :image='imageName' :title='""'/>

        <br>
        <div class='paragraph' style='text-align: left'>
            <h4>关于我们</h4>
            <br>
            <p style='font-size: 12pt'>
                六六找房是上海屋聚公共租赁住房运营有限公司面向白领客户推出的租房平台。平台坚持以“真实房源，高效找房”为目标，为房东提供房屋出租和托管服务，目前已开通北上广深杭等多个城市，服务数百万白领用户。
            </p>
        </div>
        <div class='paragraph' style='text-align: left'>
            <div>
                <span class='contact-dot'>·</span>
                商务合作、媒体/采访、客服反馈：m@zuber.im
            </div>
            <div>
                <span class='contact-dot'>·</span>
                联系电话：021-31300179
            </div>
        </div>

        <!-- <separate :title='"我们在这里"'/>
        <br>
        <p class='paragraph'>
            地址：上海市长宁区华联创意广场（江苏北路125号）A幢105、107室
        </p>
        <img src='@/assets/contact_map@2x.png' class='contact-map'/> -->

        <!-- <separate :title='"更多方式关注六六找房"'/>
        <contacts/> -->
        <official-footer>
            <div slot='extra'>
                <separate :title='"更多方式关注六六找房"'/>
                <follows/>
            </div>
        </official-footer>
    </div>
</template>

<script>
    import Banner from '@/components/Banner'
    import Separate from '@/components/Separate'
    // import Contacts from '@/components/Contacts'
    import Follows from '@/components/Follow'
    import OfficialFooter from '@/components/Footer'

    export default {
        components: {
            Banner,
            Separate,
            // Contacts,
            Follows,
            OfficialFooter
        },
        data () {
            return {
                imageName: 'bg_contact',
            }
        }
    }
</script>

<style lang='less'>
    .contact-map {
        width: 100%;
        max-width: 724px;
        display: block;
        margin: 0 auto;
        margin-bottom: 85px;
        margin-top: -30px;
    }
    .contact-dot {
        font-size: 24pt;
        color: #66D4C3;
        position: relative;
        top: 3px;
    }
</style>

