<template>
    <div class="home">
        <div class='container'>
            <div class='download screen'>
                <div  class='download-section'>
                    <img src='@/assets/home_img_phone@2x.png' class='download-section__image'/>
                </div>
                
                <div class='download-section' style='margin-top: 100px;'>
                    <img src='@/assets/home_img_font@2x.png' class='download-section__image'/>

                    <div class='download-btns'>
                        <a class='download-btns__btn' :href='ios' target='_blank'>
                            <img src='@/assets/home_icon_ios@2x.png' height="24"/>
                            IOS版
                        </a>
                        <a class='download-btns__btn' :href='android' target='_blank'>
                            <img src='@/assets/home_icon_android@2x.png' height="24"/>
                            Android版
                        </a>
                    </div>
                </div>
            </div>

            <div class='download-mobile row mobile'>
                <div class='col-6 download-mobile__section'>
                    <img src='@/assets/home_img_phone@2x.png' class='download-mobile__image'/>
                </div>
                <div class='col-6 download-mobile__section'>
                    <img src='@/assets/home_img_font@2x.png' class='download-mobile__image' style='margin-top: 100px;'/>

                    <a class='download-mobile__btn' :href='mobile' target="_blank">立即下载</a>
                </div>
            </div>

            <div class='footer'>
                © 上海屋聚公共租赁住房运营有限公司 All rights reserved. <a href='http://www.beian.miit.gov.cn' target='_blank'>沪ICP备15033530</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                ios: 'https://itunes.apple.com/cn/app/id1035547502?mt=8',
                android: 'http://www.zuber.im/android/latest',
                mobile: 'http://a.app.qq.com/o/simple.jsp?pkgname=im.zuber.app'
            }
        }
    }
</script>

<style lang="less">
    .home {
        min-height: 100vh;
        background-image: url('../assets/bg_home.png');
        background-size: cover;
        background-position: center;
        background-repeat: none;
        padding-top: 60px;
    }
    .download {
        overflow: hidden;
        margin-top: 84px;
        margin-bottom: 90px;
        width: 100%;
        max-width: 780px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        .download-section {
            flex: 1;
            padding-right: 40px;
            &:last-child {
                padding-right: 0;
                padding-left: 40px;
            }
            .download-section__image {
                width: 100%;
            }
        }
        .download-btns {
            margin-top: 80px;
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            .download-btns__btn {
                display: block;
                width: 140px;
                height: 48px;
                line-height: 48px;
                background: #fff;
                border-radius: 25px;
                color: #333;
                text-align: center;
                text-decoration: none;
                img {
                    display: inline-block;
                    position: relative;
                    left: -2px;
                    top: -3px;
                }
            }
        }
    }
    @media (max-width: 992px) {
        .download-mobile {
            margin-top: 100px;
            display: flex;
            .download-mobile__image {
                width: 100%;
            }
            .download-mobile__btn {
                display: block;
                width: 120px;
                height: 48px;
                line-height: 48px;
                background: #fff;
                border-radius: 25px;
                color: #66D4C3;
                text-align: center;
                margin-top: 30px;
                text-decoration: none;
            }
        }
    }
    
    .footer {
        text-align: center;
        color: #fff;
        margin-top: 90px;
        padding-bottom: 20px;
        font-size: 12px;
        font-weight: 200;
        a {
            color: #fff;
            text-decoration: underline;
        }
    }
</style>

