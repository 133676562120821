<template>
    <div class="about">
        <banner :image='imageName'>
            <div slot class='trust-banner'>
                <h1 class='trust-banner__title'>
                    房客安心住，与业主直签<br>
                    业主收益高，安全又省心
                </h1>
                <a class='trust-banner__btn' href='https://clause.zuber.im/trust' target='_blank'>申请合作</a>
                <p class='trust-banner__intro'>*目前仅支持上海地区，其他城市陆续开通中</p>
            </div>
        </banner>

        <!-- <div class='trust-container curtain' style='margin-top: -50px;'>
            <separate :title='"影响出租收益的因素"'/>
            <div class='affect-factors'>
                <div class='affect-factors__item'>
                    <img src='@/assets/01@2x.png'/>招租速度
                </div>
                <div class='affect-factors__item'>
                    <img src='@/assets/02@2x.png'/>出租价格
                </div>
                <div class='affect-factors__item'>
                    <img src='@/assets/03@2x.png'/>居住体验
                </div>
            </div>
        </div> -->

        <separate :title='"六六找房三大服务 提升业主出租收益"'/>

        <div class='trust-section list'>
            <div class='trust-section__item'>
                <img src='@/assets/img_serve_01@2x.png' class='trust-section__poster'/>
            </div>

            <div class='trust-section__item intro-context serve-intro'>
                <h3 class='serve-intro__title'>01 快速招租服务</h3>
                <h4 class='serve-intro__subtitle'>减少空置率，提升入住率</h4>
                <p class='serve-intro__context'>自有租房平台，链接海量白领租客</p>
            </div>
        </div>

        <div class='trust-container'>
            <div class='trust-section list'>
                <div class='trust-section__item intro-context serve-intro'>
                    <h3 class='serve-intro__title'>02 屋况升级服务</h3>
                    <h4 class='serve-intro__subtitle'>屋况品质升级<br>提升房源竞争力</h4>
                    <p class='serve-intro__context'>灯光、窗帘、桌椅、床柜、挂画等装饰美化升级，<br>
                    提升房屋受欢迎度，实现资产增值</p>
                    <p class='serve-intro__context'>家电、门锁、把手、地板、厨卫设施等维修维护，<br>
                    保障房屋设施完善，实现资产保值</p>
                </div>
                <div class='trust-section__item'>
                    <img src='@/assets/img_serve_02@2x.png' class='trust-section__poster'/>
                </div>
            </div>
        </div>

        <div class='trust-section list'>
            <div class='trust-section__item'>
                <img src='@/assets/img_serve_03@2x.png' class='trust-section__poster'/>
            </div>

            <div class='trust-section__item intro-context serve-intro'>
                <h3 class='serve-intro__title'>03 居住管理服务</h3>
                <h4 class='serve-intro__subtitle'>提升房客居住体验<br>降低退租率，提高续租率</h4>
                <p class='serve-intro__context'><strong>房屋管理：</strong>房屋家财保险、定期屋况巡检、预防<br>
                重大安全隐患</p>
                <p class='serve-intro__context'><strong>房客管理：</strong>房客筛选匹配、续租管理/纠纷<br>
                处理、保洁维修服务</p>
            </div>
        </div>

        <div class='trust-container'>
            <separate :title='"常见问题解答"'/>
            <helper/>
        </div>
        

        <separate :title='"合作流程"' :intro='"只需3步，即可开始代管服务"'/>

        <trust-progress/>

        <div class='service-phone'>
            <div class='service-phone__context'>
                <!-- <img src='@/assets/phone_icon@2x.png' style='height: 32px;margin-right: 15px'/> -->
                021-31300179
                <span style='font-size: 10pt;'>欢迎致电咨询</span>
            </div>

            <a href='https://clause.zuber.im/trust' target='_blank' class='trust-btn'>
                申请合作
            </a>
        </div>
        
        <official-footer :mode='"dark"'>
        </official-footer>
    </div>
</template>

<script>
    import Banner from '@/components/Banner'
    import Separate from '@/components/Separate'
    import Helper from '@/components/QA'
    import TrustProgress from '@/components/Progress'
    import OfficialFooter from '@/components/Footer'

    export default {
        components: {
            Banner,
            Separate,
            Helper,
            TrustProgress,
            OfficialFooter
        },
        data () {
            return {
                imageName: 'bg_trust'
            }
        }
    }
</script>

<style lang='less'>
    .trust-container {
        background: #F7F7F7;
        padding: 30px 0;
        padding-bottom: 0;
        margin-bottom: 60px;
        position: relative;
        &.curtain {
            padding-top: 60px;
            padding-bottom: 30px;
            &:before {
                content: '';
                width: 0;
                height: 0;
                border-width: 32px;
                border-style: solid;
                border-color: #f7f7f7 transparent transparent transparent;
                position: absolute;
                bottom: -64px;
                left: calc(50% - 16px);
            }
        }
    }
   .trust-section {
       max-width: 860px;
       margin: 0 auto;
       padding: 60px 0;
       display: flex;
       align-items: center;
       .trust-section__item {
           flex: 1;
           font-size: 11pt;
           &.intro-context {
               font-weight: 450;
               line-height: 2em;
               padding-left: 80px;
           }
           .trust-section__poster {
               width: 100%;
               max-height: 400px;
           }
       }
   }
   .serve-intro {
       .serve-intro__title {
           color: #0CC196;
       }
       .serve-intro__subtitle {
           padding: 30px 0;
       }
       .serve-intro__context {
           font-size: 11pt;
       }
   }
   .trust-banner {
       text-align: center;
       display: flex;
       align-items: center;
       flex-direction: column;
       justify-content: center;
       height: 500px;
       .trust-banner__title {
           margin-top: 120px;
           color: #0CC196;
           font-size: 32pt;
           font-weight: 400;
           line-height: 48pt;
       }
        .trust-banner__btn {
            color: #fff;
            background: #0CC196;
            border-radius: 4px;
            padding: 10px 15px;
            margin-top: 40px;
            margin-bottom: 40px;
            font-size: 14pt;
            text-decoration: none;
        }
        .trust-banner__intro {
            color: #fff;
            opacity: .45;
            font-size: 10pt;
        }
   }
   .service-phone {
       display: flex;
       justify-content: space-between;
       max-width: 860px;
       margin: 0 auto;
       margin-bottom: 100px;
       .service-phone__context {
           font-size: 18pt;
           font-weight: 400;
       }
   }
   .pay-section {
       display: flex;
       align-items: center;
       margin-top: 30px;
       .pay-section__item {
           font-size: 32pt;
           font-weight: 300;
           color: #0CC196;
           .unit {
               font-size: 10pt;
               color: #666;
           }
       }
       .pay-section__line {
           height: 30px;
           width: 1px;
           margin: 0 50px;
           background: #eaeaea;
       }
   }

   .trust-btn {
        display: inline-block;
        font-size: 12pt;
        background: #0CC196;
        color: #fff;
        width: 160px;
        padding: 10px;
        border-radius: 4px;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        &:hover {
            color: #fff;
            text-decoration: none;
        }
   }
   .affect-factors {
       display: flex;
       align-items: center;
       justify-content: center;
       padding: 60px 0;
       .affect-factors__item {
           flex-basis: 15%;
           display: flex;
           align-items: center;
           justify-content: center;
           font-size: 16pt;
           font-weight: 400;
           img {
               width: 50px;
               margin-right: 15px;
           }
       }
   }
   @media (max-width: 992px) {
       .list {
           display: block;
           width: 100%;
           .trust-section__item {
               margin-bottom: 30px;
               padding: 0 15px;
           }
       }
       .trust-banner {
            .trust-banner__title {
                font-size: 22pt;
            }   
       }
       .affect-factors {
           display: block;
       }
       .service-phone {
           display: block;
           text-align: center;
       }
   }
</style>

