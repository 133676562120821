<template>
    <div :class='["official-footer", mode]'>
        <slot name='extra'/>
        <img src='@/assets/logo@2x.png' height="25"/>
        <div class='official-footer__context'>
                © 上海屋聚公共租赁住房运营有限公司 All rights reserved. <a href='http://www.beian.miit.gov.cn' target='_blank'>沪ICP备15033530</a>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['mode']
    }
</script>


<style lang="less">
    .official-footer {
        a {
            color: #333;
            text-decoration: underline;
        }
        &.dark {
            background: rgba(22, 18, 50, 0.8);
            color: #fff;
            a {
                color: #fff;
            }
        }
    }
</style>
