<template>
    <div class='qa-container'>
        <div class='question-list'>
            <div v-for='(qa, index) in list' :key='index' class='question-list__item' @click='view(index)'>
                {{qa.question}}
            </div>
        </div>

        <div class='question-modal'>
            <div class='modal-mask' v-if='qindex != null'/>

            <transition name='push' mode='out-in'>
                <div class='modal-container' v-if='qindex != null'>
                    <div class='modal-box'>
                        <img src='@/assets/close@2x.png' class='modal-box__close' @click='close'/>
                        <h5>{{list[qindex].question}}</h5>
                        <br>
                        <p>{{list[qindex].answer}}</p>
                        <div class='question-table' v-if='list[qindex].table'>
                            <div v-for='(item, tindex) in list[qindex].table' :key='tindex' class='question-table__item'>
                                {{item}}
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                qindex: null,
                list: [
                    {
                        question: '如何收费及代管多久',
                        answer: '代租代管运营期间，我方收取每笔租金的8%作为管理费用；日常屋况维修维护与装饰升级的费用统一由业主承担，我司负责制定方案和管理。管理期限24个月起。'
                    },
                    {
                        question: '代管期间为业主提供哪些服务？',
                        answer: '六六找房提供专业服务如下表：',
                        table: ['租金评估与收益优化', '屋况评估与升级定制', '专业房屋摄影', '多渠道招租', '房客入住前的筛选', '房客须知确认', '租赁合同签订', '租金催缴与专项账户结转', '水电煤费用代收与催缴', '月度上门保洁', '屋况巡视与维持', '屋内设施报修维修', '退租费用结算', '']
                    },
                    // {
                    //     question: '为何建议按单间出租？',
                    //     answer: '房屋按单间出租，“门槛低、受众广、租得快”。城市租房主力为年轻白领，受限于工资收入，他们更多地会选择合租。因此按单间出租的需求旺盛，能显著“提升入住率”，只要出租掉一间，就立马能产生收益，避免整套出租的空置风险。另外，我司还提供专业的空间改造业务，根据房屋实际情况提高可居住空间利用率，使业主能够获得更高的收益。'
                    // },
                    {
                        question: '六六找房代租代管是否安全？',
                        answer: '非常安全。①租期租金透明，杜绝“高收低租”行为；②房屋房客管理公开透明，全流程使用智能设备和SaaS软件，业主随时监督；③资金交易透明，往来资金均由监管银行结算。'
                    },
                    {
                        question: '六六找房与传统中介区别？',
                        answer: '传统中介因其获客与运营成本高等因素，均以房屋买卖为主，即使是房屋租赁业务也以整租为主，并且几乎不涉及租后管理，租客素质和履约能力得不到管控，业主还需要花费不小的精力来打理房屋、管理房客。而六六找房依托自建网络平台获客优势与运营成本优势，代理业主开展精细化住房租赁全流程运营服务，使租客更稳定，业主更高收。'
                    },
                    {
                        question: '租客跟谁签约？是否有保障？',
                        answer: '租客直接与业主签约。我司代管房源的租房交易透明化，不存在租金差，也不存在二房东“跑路”的风险，屋内维修等问题也都会由我司的专业售后服务团队解决，租客有保障、放心住。'
                    }
                ]
            }
        },
        methods: {
            view (index) {
                this.qindex = index
            },
            close () {
                this.qindex = null
            }
        }
    }
</script>

<style lang="less" scoped>
    .question-list {
        max-width: 860px;
        margin: 0 auto;
        overflow: hidden;
        padding: 60px 0;
        .question-list__item {
            float: left;
            width: calc(50% - 15px);
            border-bottom: 1px solid #eee;
            padding-bottom: 15px;
            margin-bottom: 15px;
            cursor: pointer;
            &:nth-child(odd) {
                margin-right: 15px;
            }
            &:nth-child(even) {
                margin-left: 15px;
            }
        }
    }
    .question-modal {
        .modal-mask {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: #333;
            z-index: 99;
            opacity: .75;
        }
        .modal-container {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            flex-direction: column;
            z-index: 100;
            .modal-box {
                background: #fff;
                margin: 0 auto;
                padding: 80px 30px;
                width: 580px;
                position: relative;
                max-height: 640px;
                overflow-y: auto;
                .modal-box__close {
                    width: 16px;
                    position: absolute;
                    right: 40px;
                    top: 30px;
                    cursor: pointer;
                }
            }
        }
    }
    .question-table {
        overflow: hidden;
        border-left: 1px solid #eee;
        border-top: 1px solid #eee;
        .question-table__item {
            float: left;
            width: 50%;
            border-bottom: 1px solid #eee;
            border-right: 1px solid #eee;
            height: 47px;
            line-height: 47px;
            box-sizing: border-box;
            padding: 0 30px;
        }
    }
    @media (max-width: 992px) {
        .question-list {
            .question-list__item {
                width: 100%;
                margin-left: 0 !important;
                margin-right: 0 !important;
                padding-left: 30px;
            }
        }
        
        .question-modal {
            .modal-container {
                .modal-box {
                    width: 80%;
                }
            }
            
        }
        .question-table {
            .question-table__item {
                width: 100%;
                font-size: 10pt;
            }
        }
    }
</style>

