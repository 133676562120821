<template>
    <div class='banner' :style='{backgroundImage: `url(${require("@/assets/" + image + ".png")})`}'>
        <h1 class='banner-title' v-if='title'>{{title}}</h1>
        <p class='banner-subtitle' v-if='subtitle'>{{subtitle}}</p>
        <slot/>
        <!-- <div class='banner-border'></div> -->
    </div>
</template>

<script>
    export default {
        props: ['image', 'title', 'subtitle']
    }
</script>

<style lang='less'>
    .banner {
        height: 500px;
        background-size: cover;
        background-position: center;
        background-repeat: none;
        margin-bottom: 40px;
        .banner-title {
            padding-top: 210px;
            text-align: center;
            color: #fff;
            font-weight: 300;
        }
        .banner-subtitle {
            text-align: center;
            color: #fff;
            font-size: 20pt;
            font-weight: 200;
        }
        .banner-border {
            width: 80px;
            height: 4px;
            background-color: #fff;
            margin: 0 auto;
            margin-top: 10px;
            border-radius: 3px;
        }
    }
</style>


